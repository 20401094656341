(async function () {
  const code = new URL(document.location.href).searchParams.get('code');
  const isLocalhost = document.location.href.includes('localhost');
  const redirectUri = isLocalhost ? 'http://localhost:3000' : 'https://nightcore.app';

  if (code) {
    const reqUrl = new URL('https://patreon.nightcore.app/login');

    reqUrl.searchParams.append('code', code);
    reqUrl.searchParams.append('redirectUri', redirectUri);

    const response = await fetch(reqUrl.toString());
    const json = await response.json();

    localStorage.setItem('patreon', JSON.stringify(json));

    document.location.href = document.location.href.split('?')[0];
  }
})();
