import { Component, registerComponent } from 'webact';
import '../dark-mode-toggle/dark-mode-toggle.js';

function stringToElements (string) {
  return document.createRange().createContextualFragment(string);
}

function handleSettingsButtonClick () {
  const infoBox = document.querySelector('info-box#settings');
  const open = infoBox.getAttribute('open');

  if (open) {
    infoBox.removeAttribute('open');
  } else {
    infoBox.setAttribute('open', 'open');
  }
}

/*
function handleInfoButtonClick () {
  const infoBox = document.querySelector('info-box');
  const open = infoBox.getAttribute('open');

  if (open) {
    infoBox.removeAttribute('open');
  } else {
    infoBox.setAttribute('open', 'open');
  }
}
*/

function handleOpenFile (event) {
  if (!('Vibrant' in window)) {
    document.head.appendChild(
      stringToElements(`
        <script src="https://cdn.jsdelivr.net/npm/node-vibrant@3.1.3/dist/vibrant.min.js"></script>
      `)
    );
  }

  if (!('jsmediatags' in window)) {
    document.head.appendChild(
      stringToElements(`
      <script src="https://cdn.jsdelivr.net/npm/jsmediatags@3.8.1/dist/jsmediatags.min.js"></script>
      `)
    );
  }

  document.dispatchEvent(new CustomEvent('app-header:open-files', {
    detail: {
      files: event.target.files
    }
  }));
}

class AppHeader extends Component {
  constructor () {
    super('/js/components/app-header/app-header.js');
  }

  registerEventListeners () {
    const fileInput = this.$('#input-file');
    const openFileButton = this.$('#btn-open-file');
    const settingsButton = this.$('#btn-settings');

    openFileButton.addEventListener('click', () => fileInput.click());
    fileInput.addEventListener('change', handleOpenFile);
    // infoButton.addEventListener('click', () => handleInfoButtonClick());
    settingsButton.addEventListener('click', () => handleSettingsButtonClick());
  }

  componentDidMount () {
    this.registerEventListeners();
    this.ensureDarkMode();

    document.addEventListener('colorschemechange', e => {
      if (e instanceof CustomEvent) {
        const forceDark = e.detail.colorScheme === 'dark';

        this.ensureDarkMode(forceDark);
      }
    });
  }

  ensureDarkMode (forceDark) {
    if (forceDark === undefined) {
      forceDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    this.classList.toggle('dark', forceDark);
  }
}

export default registerComponent(AppHeader, {
  name: 'app-header'
});
