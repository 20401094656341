/* eslint-disable no-unused-expressions */
import { registerFunctionComponent } from 'webact';

async function AppButton () {
  const { $, html, useCSS, postRender } = this;

  html`<slot></slot>`;
  await useCSS('/js/components/app-button/app-button.css');

  const ensureDarkMode = forceDark => {
    if (forceDark === undefined) {
      forceDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    $(':host').classList.toggle('dark', forceDark);
  };

  postRender(() => {
    ensureDarkMode();

    document.addEventListener('colorschemechange', e => {
      if (e instanceof CustomEvent) {
        const forceDark = e.detail.colorScheme === 'dark';

        ensureDarkMode(forceDark);
      }
    });
  });
}

export default registerFunctionComponent(AppButton, {
  name: 'app-button'
});
