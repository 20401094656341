import { Component, registerComponent } from 'webact';

import { nightcorePlayer } from '../../nightcore-player.js';

import '../shuffle-button/shuffle-button.js';
import '../layout-box/layout-box.js';

function stringToElements (string) {
  const fragment = document.createRange().createContextualFragment(string);

  return [...fragment.children];
}

function shuffle (array) {
  let m = array.length;
  let t;
  let i;

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(Math.random() * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
}

class PlayQueue extends Component {
  constructor () {
    super('/js/components/play-queue/play-queue.js');

    this.refs = {};
    this.queueState = {
      items: [
        /*
        {
          fileName: 'Elevate Promotions - [PROMO] Dance All Night.mp3',
          url: '/music/Elevate-Promotions-Dance-All-Night.mp3',
          title: '[PROMO] Dance All Night',
          artist: 'Elevate Promotions',
          id: '10b5169a-1339-4f39-8fb3-7e076ff70264-promo'
        }
        */
      ],
      activeItemId: null
    };
  }

  ensureDarkMode (forceDark) {
    if (forceDark === undefined) {
      forceDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    this.classList.toggle('dark', forceDark);
  }

  componentDidMount () {
    this.refs = {
      queue: this.$('#queue'),
      activeItemStyles: this.$('#active-item-styles')
    };

    this.registerEventListeners();

    if (this.queueState.items.length > 0) {
      this.renderItems();
    }

    this.ensureDarkMode();

    document.addEventListener('colorschemechange', e => {
      if (e instanceof CustomEvent) {
        const forceDark = e.detail.colorScheme === 'dark';

        this.ensureDarkMode(forceDark);
      }
    });
  }

  updateActiveItemIndication (id) {
    this.refs.activeItemStyles.innerHTML = `
      li[data-id="${id}"] {
        background-color: var(--player-vibrant) !important;
        color: white !important;
      }

      li[data-id="${id}"] .remove-item-button {
        display: none;
      }
    `;
  }

  handleRemove (idToRemove) {
    if (idToRemove === this.queueState.activeItemId) {
      return;
    }

    this.queueState.items = this.queueState.items.filter(id => id !== idToRemove);
    this.$(`li[data-id="${idToRemove}"]`).remove();
  }

  renderItems () {
    if (this.queueState.items.length === 0) {
      return;
    }

    const listItems = this.queueState.items
      .map(({ title, artist, id, imageURL }) => `
        <li data-id=${id}>
          <figure>
            <img src="${imageURL || 'img/missing-cover-2x.png'}" alt="${title}">
          </figure>
          <div class="wrapper">
            <strong>${title}</strong>
            <span>${artist}</span>
          </div>
          <div class="actions"></div>
        </li>
        `)
      .map(stringToElements)
      .map(elements => elements[0]);

    this.refs.queue.innerHTML = null;

    listItems.forEach(li => {
      const removeButton = document.createElement('button');

      removeButton.className = 'remove-item-button';

      removeButton.addEventListener('click', event => {
        event.preventDefault();
        event.stopPropagation();

        this.handleRemove(li.getAttribute('data-id'));
      });

      removeButton.innerHTML = `
        <svg width="15" height="15"><use href="img/icons.svg#remove" /></svg>
      `;

      li.querySelector('.actions').appendChild(removeButton);

      requestAnimationFrame(() => this.refs.queue.appendChild(li));
    });
  }

  /**
   * @param {CustomEvent} event
   */
  async handlePlayQueueAdd (event) {
    const preloadedPromo = this.queueState.items.length > 0 && this.queueState.items[0].title.indexOf('[PROMO]') !== -1;

    if (preloadedPromo) {
      this.queueState.items = [];
    }

    const wasEmpty = this.queueState.items.length === 0;

    const { items } = event.detail;

    this.queueState.items = this.queueState.items.concat(items);

    this.renderItems();

    if (wasEmpty) {
      nightcorePlayer.resume();
      document.dispatchEvent(new CustomEvent('play-queue:request-next-song'));
    }
  }

  handlePlayQueueGetNextSong () {
    const indexOfActiveId = this.queueState.items.findIndex(item => item.id === this.queueState.activeItemId);
    let nextItemIndex = indexOfActiveId + 1;

    if (!this.queueState.items[nextItemIndex]) {
      nextItemIndex = 0;
    }

    const nextItem = this.queueState.items[nextItemIndex];

    document.dispatchEvent(new CustomEvent('play-queue:play-song', {
      detail: nextItem
    }));

    this.queueState.activeItemId = nextItem.id;
    this.updateActiveItemIndication(nextItem.id);
  }

  handleShuffle () {
    const shuffledItems = shuffle(this.queueState.items).sort((a, b) => {
      return a.id === this.queueState.activeItemId ? -1 : b.id === this.queueState.activeItemId ? 1 : 0;
    });

    this.queueState.items = shuffledItems;
    this.renderItems();
  }

  handleToggleOpen () {
    if (this.getAttribute('open')) {
      this.removeAttribute('open');
    } else {
      this.setAttribute('open', 'open');
    }
  }

  async handleClickQueue (event) {
    if (event.target.tagName.toLowerCase() === 'li') {
      const idOfItemClicked = event.target.getAttribute('data-id');

      if (nightcorePlayer.audioContext.state === 'suspended') {
        await nightcorePlayer.resume();
      }

      document.dispatchEvent(new CustomEvent('play-queue:play-song', {
        detail: this.queueState.items.find(item => item.id === idOfItemClicked)
      }));

      this.queueState.activeItemId = idOfItemClicked;
      this.updateActiveItemIndication(idOfItemClicked);
    }
  }

  registerEventListeners () {
    this.refs.queue.addEventListener('click', event => this.handleClickQueue(event), false);

    document.addEventListener('play-queue:add', event => {
      if (event instanceof CustomEvent) {
        this.handlePlayQueueAdd(event);
      }
    }, false);

    document.addEventListener('play-queue:request-next-song', () => this.handlePlayQueueGetNextSong(), false);
    document.addEventListener('play-queue:shuffle', () => this.handleShuffle(), false);
    document.addEventListener('play-queue:toggle', () => this.handleToggleOpen(), false);
  }
}

export default registerComponent(PlayQueue, {
  name: 'play-queue'
});
