import { Component, registerComponent } from 'webact';

class LayoutBox extends Component {
  constructor () {
    super('/js/components/layout-box/layout-box.js');
  }

  ensureDarkMode (forceDark) {
    if (forceDark === undefined) {
      forceDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    this.classList.toggle('dark', forceDark);
  }

  componentDidMount () {
    this.ensureDarkMode();

    document.addEventListener('colorschemechange', e => {
      if (e instanceof CustomEvent) {
        const forceDark = e.detail.colorScheme === 'dark';

        this.ensureDarkMode(forceDark);
      }
    });
  }
}

export default registerComponent(LayoutBox, {
  name: 'layout-box'
});
