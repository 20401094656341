import { registerFunctionComponent } from 'webact';

function VersionNumber() {
  const { $, postRender } = this;

  postRender(async () => {
    const response = await fetch('/package.json');
    const json = await response.json();

    $(':host').innerHTML = `${json.version}`;

    /*
    if ('plausible' in window) {
      // @ts-ignore
      window.plausible('version', {
        props: {
          version: json.version
        }
      });
    }
    */
  });
}

registerFunctionComponent(VersionNumber, {
  name: 'version-number'
});
