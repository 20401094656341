/* eslint-disable no-unused-expressions */
import { registerFunctionComponent } from 'webact';

async function PatreonUser () {
  const { html, useCSS, postRender, $ } = this;

  html`
    <div id="wrapper">
      <div id="login-status"></div>
    </div>
  `;

  await useCSS('/js/components/patreon-user/patreon-user.css');

  function logOut () {
    localStorage.removeItem('patreon');
    document.location.reload();
  }

  postRender(async () => {
    const loginStatusEl = $('#login-status');
    const jsonString = localStorage.getItem('patreon');

    if (jsonString !== null) {
      const { access_token: accessToken } = JSON.parse(jsonString);

      const response = await fetch('https://patreon.nightcore.app/identity', {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      const json = await response.json();

      if (json.errors) {
        logOut();
      }

      if (json) {
        const wrapperEl = $('#wrapper');

        const { full_name: fullName, image_url: imageUrl } = json.data.attributes;

        loginStatusEl.classList.remove('space-around');

        loginStatusEl.innerHTML = `
          <figure>
            <img src="${imageUrl}" alt="Image of ${fullName}">
          </figure>
          Welcome,&nbsp;<span>${fullName}</span>!
        `;

        const responseTwo = await fetch('https://patreon.nightcore.app/patron', {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });

        if (responseTwo.ok) {
          wrapperEl.innerHTML += '<p>You are supporting the app on Patreon! Thank you!<br />You will now be able to download songs.</p>';
        } else {
          wrapperEl.innerHTML += '<p>You are currently not supporting the app on Patreon!<br />When you do you will gain more features, like being able to download songs.<br /><patreon-support></patreon-support></p>';
        }

        wrapperEl.innerHTML += `
          <app-button id="log-out-button">
            <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M13.5 7.5l-3 3.25m3-3.25l-3-3m3 3H4m4 6H1.5v-12H8" stroke="currentColor"></path></svg>
            <span>Log out</span>
          </app-button>
        `;

        $('#log-out-button').addEventListener('click', () => logOut(), false);
      }
    } else if (new URL(document.location.href).searchParams.get('code') === null) {
      const isLocalhost = document.location.href.includes('localhost');
      const redirectUri = isLocalhost ? 'http://localhost:3000' : 'https://nightcore.app';
      const loginUrl = 'https://www.patreon.com/oauth2/authorize?response_type=code&client_id=CogoiA8AvZFxNxqYkQZDIp7I2LjPBdx9--9QseAoHastyMcvsDL8V3Es-thvLQbW&redirect_uri=' + redirectUri;

      loginStatusEl.innerHTML = `
        <a href="${loginUrl}">
          <app-button>
            <svg width="15" height="15"><use href="img/icons.svg#link" /></svg>
            <span>Login</span>
          </app-button>
        </a>
        <a href="https://www.patreon.com/nightcoreapp" target="_blank">
          <app-button>
            <svg width="15" height="15"><use href="img/icons.svg#diamond" /></svg>
            <span>Support</span>
          </app-button>
        </a>
      `;
    }
  });
}

export default registerFunctionComponent(PatreonUser, {
  name: 'patreon-user'
});
