/* eslint-disable no-unused-expressions */
import { registerFunctionComponent } from 'webact';

function LazyLoadedFacebookLikes () {
  const { html, postRender, $ } = this;

  html`
    <iframe title="Facebook Likes" src="" width="100%" height="20" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true"></iframe>
  `;

  function loadFacebook () {
    const iframe = $('iframe');

    iframe.src = 'https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fncorerocks&width=450&layout=standard&action=like&size=small&show_faces=true&share=true&height=80&appId=155661588327985';
  }

  postRender(() => {
    const io = new IntersectionObserver(entries => entries.forEach(entry => {
      if (entry.isIntersecting) {
        loadFacebook();
      }
    }));

    io.observe($());
  });
}

export default registerFunctionComponent(LazyLoadedFacebookLikes, {
  name: 'lazy-loaded-facebook-likes'
});
