import { Component, registerComponent } from 'webact';

import { nightcorePlayer } from '../../nightcore-player.js';

import { CoverArt } from '../cover-art/cover-art.js';
import ProgressBar from '../progress-bar/progress-bar.js';

// eslint-disable-next-line no-unused-vars
import AudioVisualiser from '../audio-visualiser/audio-visualiser.js';
import '../download-button/download-button.js';

class FooterPlayer extends Component {
  constructor () {
    super('/js/components/footer-player/footer-player.js');
  }

  handleMetadataChange () {
    this.bottomRow.classList.add('visible');
  }

  handlePlayerColours (event) {
    const { colours } = event.detail;
    const [fgl, fg, bg] = colours;

    if (!this.audioVisualiser) {
      const audioVisualiser = document.createElement('audio-visualiser');

      this.topRow.appendChild(audioVisualiser);

      /** @type {AudioVisualiser} */
      // @ts-ignore
      this.audioVisualiser = audioVisualiser;
    }

    this.footer.style.setProperty('--vibrant', fgl);
    this.footer.style.setProperty('--base', bg);

    document.body.style.setProperty('--player-dark', bg);
    document.body.style.setProperty('--player-vibrant', fg);
    document.body.style.setProperty('--player-light', fgl);

    this.audioVisualiser.setAttribute('color', fg);
  }

  handleDurationChange (event) {
    const { orginalDuration, playbackRate } = event.detail;

    this.progressBar.duration = orginalDuration;
    this.progressBar.playbackRate = playbackRate;
  }

  handlePlayStateChange (event) {
    const { playState } = event.detail;

    if (playState === 'PLAYING') {
      this.togglePlaybackButton.querySelector('#play').style.display = 'none';
      this.togglePlaybackButton.querySelector('#pause').style.display = 'block';
      this.audioVisualiser.analyser = nightcorePlayer.analyser;
      this.audioVisualiser.start();

      this.progressBar.start();
    } else {
      this.togglePlaybackButton.querySelector('#play').style.display = 'block';
      this.togglePlaybackButton.querySelector('#pause').style.display = 'none';
      this.audioVisualiser.stop();
      this.progressBar.stop();
    }
  }

  handleComputedPlaybackRateChange (event) {
    const { computedPlaybackRate } = event.detail;

    this.progressBar.playbackRate = computedPlaybackRate;
  }

  registerEventListeners () {
    document.addEventListener('metadata-change', () => this.handleMetadataChange());
    document.addEventListener('player:colours', event => this.handlePlayerColours(event));
    document.addEventListener('player:duration-change', event => this.handleDurationChange(event));
    document.addEventListener('player:play-state-change', event => this.handlePlayStateChange(event));
    document.addEventListener('player:computed-playback-rate-change', event => this.handleComputedPlaybackRateChange(event));

    this.togglePlaybackButton.addEventListener('click', () => (
      document.dispatchEvent(new CustomEvent('player:toggle-playback'))
    ));

    this.toggleQueueButton.addEventListener('click', () => {
      document.dispatchEvent(new CustomEvent('play-queue:toggle'));
    });

    this.coverArtWrapper.addEventListener('click', () => this.toggleNowPlaying());
  }

  toggleNowPlaying () {
    this.nowPlaying = !this.nowPlaying;

    if (this.nowPlaying) {
      this.classList.add('now-playing');
      this.footer.classList.add('now-playing');
    } else {
      this.classList.remove('now-playing');
      this.footer.classList.remove('now-playing');
    }
  }

  cacheDOMRefs () {
    this.coverArtWrapper = this.$('.cover-art-wrapper');
    this.footer = this.$('footer');
    this.topRow = this.$('.top-row');
    this.bottomRow = this.$('.bottom-row');
    this.togglePlaybackButton = this.$('#toggle-playback');
    this.toggleQueueButton = this.$('#toggle-queue');

    const progressBar = this.$('progress-bar');
    const coverArt = this.$('cover-art');

    if (coverArt instanceof CoverArt) {
      this.coverArt = coverArt;
    }

    if (progressBar instanceof ProgressBar) {
      this.progressBar = progressBar;
    }
  }

  componentDidMount () {
    this.cacheDOMRefs();
    this.registerEventListeners();
  }
}

export default registerComponent(FooterPlayer, {
  name: 'footer-player'
});
