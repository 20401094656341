/* eslint-disable no-unused-expressions */
import { registerFunctionComponent } from 'webact';

let clr;

function StatusText () {
  const { html, css, postRender, $ } = this;

  postRender(() => {
    const span = $('span');

    document.addEventListener('status', e => {
      const { message, action } = e.detail;

      if (e instanceof CustomEvent) {
        if (clr && !action) {
          clearTimeout(clr);
        }

        span.textContent = message;

        if (action !== 'reload') {
          clr = setTimeout(() => {
            span.textContent = null;
          }, 4000);
        }
      }
    });
  });

  html`
    <span></span>
  `;

  css`
    :host {
      pointer-events: none;
      width: 100%;
      height: 0;
    }

    span {
      display: block;
      width: 100%;
      background-color: var(--darker-pink);
      color: white;
      font-size: 10pt;
      padding: 1em;
      border-radius: 4px;
      transform: none;
      transition: all 250ms ease;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    span:empty {
      opacity: 0;
      transform: translateY(-100%);
    }
  `;
}

export default registerFunctionComponent(StatusText, {
  name: 'status-text'
});
