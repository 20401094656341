/* eslint-disable no-unused-expressions */
import { registerFunctionComponent } from 'webact';

function ShuffleButton () {
  const { html, css, postRender, $ } = this;

  html`
    <span>Shuffle</span>
  `;

  css`
    :host {
      all: unset;
      background-color: currentColor;
      padding: 0.5em;
      border-radius: 8px;
      box-sizing: border-box;
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      color: white;
    }
  `;

  postRender(() => {
    $(':host').addEventListener('click', () => {
      document.dispatchEvent(new CustomEvent('play-queue:shuffle'));
    });
  });
}

export default registerFunctionComponent(ShuffleButton, {
  name: 'shuffle-button'
});
