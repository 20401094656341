export async function isPatron () {
  try {
    const jsonString = localStorage.getItem('patreon');

    if (jsonString === null) {
      return false;
    }

    const { access_token: accessToken } = JSON.parse(jsonString);
    const response = await fetch('https://patreon.nightcore.app/patron', {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });

    return response.ok;
  } catch (e) {
    console.error(e);
  }
}
