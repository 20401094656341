class SettingsRememberer {
  static storeSettings (fileName, settings) {
    try {
      localStorage.setItem(fileName, JSON.stringify(settings));
    } catch (e) {
      console.error(e);
    }
  }

  static getStoredSettings (fileName) {
    try {
      const stored = localStorage.getItem(fileName);

      return stored ? JSON.parse(stored) : undefined;
    } catch (e) {
      console.error(e);
    }
  }
}

export default SettingsRememberer;
