// @ts-check

import { audioBufferToWav } from './audio-exporter.js';
import Observer from './observer.js';
import { isPatron } from './helpers.js';

const audioElement = document.querySelector('audio');

let hasHadFirstPlayback = false;
let stateInterval;
let lastCtxState;

// @ts-ignore
window.AudioContext = window.AudioContext || window.webkitAudioContext;

/** @type {AudioContext|null} */
const audioCtx = new AudioContext();
/** @type {AudioBuffer|null} */
let currentAudioBuffer = null;
/** @type {AnalyserNode|null} */
let analyser = null;
/** @type {AudioBufferSourceNode|null} */
let source = null;
/** @type {GainNode|null} */
let gainNode = null;
/** @type {MediaElementAudioSourceNode|null} */
let mediaElementSource = null;

gainNode = audioCtx.createGain();
analyser = audioCtx.createAnalyser();

gainNode.connect(analyser);
analyser.connect(audioCtx.destination);

async function audioContextResume(ctx) {
  return Promise.race([
    ctx.resume(),
    new Promise(r => setTimeout(r, 1000))
  ]);
}

/** @typedef State
 *  @prop {number} startedAtCtxTime
 *  @prop {number} pausedAt
 *  @prop {number} playbackRate
 *  @prop {number} detune
 *  @prop {number} volume
 *  @prop {boolean} playing
 *  @prop {string|null} currentImage
 *  @prop {string|null} currentMetadata
 */

/** @typedef StateAdditions
 *  @prop {number} [startedAtCtxTime]
 *  @prop {number} [pausedAt]
 *  @prop {number} [playbackRate]
 *  @prop {number} [detune]
 *  @prop {number} [volume]
 *  @prop {boolean} [playing]
 *  @prop {string|null} [currentImage]
 *  @prop {string|null} [currentMetadata]
 */

class NightcorePlayer {
  constructor() {
    /** @type {State} */
    this._state = Object.freeze({
      startedAtCtxTime: 0,
      pausedAt: 0,
      detune: 0,
      playbackRate: 1.1,
      volume: 0.8,
      playing: false,
      currentImage: null,
      currentMetadata: null
    });

    this.playStateObserver = new Observer();
    this.playbackRateObserver = new Observer();
    this.detuneObserver = new Observer();
    this.volumeObserver = new Observer();
    this.currentMetadataObserver = new Observer();
    this.currentImageObserver = new Observer();

    document.addEventListener('progress-bar:seek', event => {
      if (event instanceof CustomEvent) {
        this.handleProgressSeek(event);
      }
    });
    document.addEventListener('player:toggle-playback', () => this.togglePlayback(), false);
    document.addEventListener('player:trigger-download', () => this.download(), false);
  }

  get analyser() {
    return analyser;
  }

  get audioContext() {
    return audioCtx;
  }

  /**
   * @param {CustomEvent} customEvent
   */
  handleProgressSeek(customEvent) {
    const { detail } = customEvent;

    this.seekToPercent(detail.percent);
  }

  seekToPercent(percentage) {
    const startAt = (currentAudioBuffer.duration * percentage);

    this.pause();
    this.play(startAt);
  }

  get currentTime() {
    const { startedAtCtxTime } = this._state;

    const currentTime = Math.abs(startedAtCtxTime - audioCtx.currentTime);

    return currentTime * this.computedPlaybackRate;
  }

  get duration() {
    return (currentAudioBuffer.duration / this.computedPlaybackRate);
  }

  set currentImage(currentImage) {
    this.updateState({ currentImage });
    this.currentImageObserver.notifyListeners(currentImage);
  }

  get currentImage() {
    return this._state.currentImage;
  }

  set currentMetadata(currentMetadata) {
    this.updateState({ currentMetadata });
    this.currentMetadataObserver.notifyListeners(currentMetadata);
  }

  get currentMetadata() {
    return this._state.currentMetadata;
  }

  set volume(volume) {
    this.updateState({ volume });

    if (gainNode) {
      try {
        gainNode.gain.setValueAtTime(volume, audioCtx.currentTime + 0.1);
      } catch (error) {
        gainNode.gain.value = volume;
      }
    }

    this.volumeObserver.notifyListeners(volume);
  }

  get volume() {
    return this._state.volume;
  }

  get playing() {
    return this._state.playing;
  }

  set playbackRate(playbackRate) {
    this.updateState({ playbackRate });

    if (source) {
      source.playbackRate.value = playbackRate;
    }

    this.playbackRateObserver.notifyListeners(playbackRate);
    this.dispatchComputedPlaybackRateChange();
  }

  get playbackRate() {
    return this._state.playbackRate;
  }

  resetPlaybackRate() {
    this.playbackRate = 1.0;
  }

  resume() {
    return audioContextResume(this.audioContext);
  }

  /**
   * @param {number} detune
   */
  set detune(detune) {
    this.updateState({ detune });

    try {
      if (source && source.detune) {
        source.detune.value = detune;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      document.dispatchEvent(new CustomEvent('player:no-detune'));
    }

    this.detuneObserver.notifyListeners(detune);
    this.dispatchComputedPlaybackRateChange();
  }

  dispatchComputedPlaybackRateChange() {
    document.dispatchEvent(new CustomEvent('player:computed-playback-rate-change', {
      detail: {
        computedPlaybackRate: this.computedPlaybackRate
      }
    }));
  }

  get detune() {
    return this._state.detune;
  }

  resetDetune() {
    this.detune = 0;
  }

  get computedPlaybackRate() {
    return this.playbackRate * Math.pow(2, this.detune / 1200);
  }

  togglePlayback() {
    const { playing } = this._state;

    if (playing) {
      this.pause();
    } else {
      this.play();
    }
  }

  ensureState () {
    if (audioCtx.state !== lastCtxState) {
      this.updateState({
        playing: audioCtx.state === 'running'
      });

      this.playStateObserver.notifyListeners(audioCtx.state === 'running' ? 'PLAYING' : 'PAUSED');
    }
  }

  /**
   * Starts playback from specified point.
   *
   * @param {number} [startAt]
   * @returns
   */
  async play(startAt) {
    const { pausedAt } = this._state;

    startAt = startAt || pausedAt;

    stateInterval = setInterval(this.ensureState.bind(this), 500);

    if (!hasHadFirstPlayback) {
      hasHadFirstPlayback = true;
    }

    try {
      await audioContextResume(audioCtx);

      source.start(0, startAt);

      this.updateState({
        startedAtCtxTime: audioCtx.currentTime,
        pausedAt: startAt
      });

      this.progressInterval = setInterval(() => {
        const accumulatedCurrentTime = this._state.pausedAt + this.currentTime;

        if (accumulatedCurrentTime < currentAudioBuffer.duration) {
          return;
        }

        this.pause();
        this.playStateObserver.notifyListeners('ENDED');
      }, 500);
    } catch (e) {
      console.error(e);
    }

    try {
      if ('mediaSession' in navigator) {
        audioElement.volume = 0;
        await audioElement.play();

        const obj = {
          title: this.currentMetadata.split('-')[1].trim(),
          artist: this.currentMetadata.split('-')[0].trim(),
          album: 'Nightcore App'
        };

        if (this.currentImage) {
          obj.artwork = [
            { src: this.currentImage }
          ];
        }

        // @ts-ignore
        // eslint-disable-next-line no-undef
        navigator.mediaSession.metadata = new MediaMetadata(obj);

        await audioElement.pause();
      }
    } catch (error) {
      console.error(error);
    }
  }

  pause() {
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
    }

    const playing = false;

    audioCtx.suspend();

    const pausedAt = this._state.pausedAt + this.currentTime;

    this.updateState({ playing, pausedAt });
    this.playStateObserver.notifyListeners('PAUSED');

    if (stateInterval) {
      clearInterval(stateInterval);
    }

    if (source) {
      this.reloadSource();
    }
  }

  /**
   *
   * @param {StateAdditions} additions
   */
  updateState(additions) {
    const newState = Object.freeze(Object.assign({}, this._state, additions));

    this._state = newState;
  }

  async download() {
    const allowedToDownload = await isPatron();

    plausible('Download', {
      props: {
        isPatron: allowedToDownload
      }
    });

    if (!allowedToDownload) {
      document.dispatchEvent(new CustomEvent('status', {
        detail: {
          message: 'You are not allowed to download. You need to be an active subscriber on Patreon.'
        }
      }));

      return;
    }

    document.dispatchEvent(new CustomEvent('status', {
      detail: {
        message: 'Processing download...'
      }
    }));
    const audioSource = await audioBufferToWav(currentAudioBuffer, { detune: this.detune, playbackRate: this.playbackRate });
    const anchor = document.createElement('a');

    document.body.appendChild(anchor);

    anchor.href = audioSource;
    anchor.setAttribute('download', `${this.currentMetadata}.S${this.playbackRate * 100}D${this.detune}.nightcore.app.wav`);
    anchor.click();

    anchor.remove();
    URL.revokeObjectURL(audioSource);
    document.dispatchEvent(new CustomEvent('status', {
      detail: {
        message: 'Download is done. Enjoy!'
      }
    }));
  }

  reloadSource() {
    source.disconnect();
    source = audioCtx.createBufferSource();
    source.buffer = currentAudioBuffer;
    source.playbackRate.value = this.playbackRate;

    // Safari does not have detune
    if (source.detune) {
      source.detune.value = this.detune;
    }

    source.connect(gainNode);
  }

  async loadAudioFromElement() {
    if (source) {
      source.disconnect();
    }

    audioElement.volume = 0;

    if (!mediaElementSource) {
      mediaElementSource = audioCtx.createMediaElementSource(audioElement);

      mediaElementSource.connect(this.audioContext.destination);
    }
  }

  /**
   * @param {{audioBuffer: (AudioBuffer|null), playToo: boolean}} loadAudioObject
   */
  async loadAudio(loadAudioObject) {
    const { playToo = false } = loadAudioObject;
    let { audioBuffer } = loadAudioObject;
    const reportDuration = audioBuffer !== currentAudioBuffer;

    if (!audioBuffer && currentAudioBuffer) {
      audioBuffer = currentAudioBuffer;
    }

    if (source) {
      source.disconnect();
    }

    source = audioCtx.createBufferSource();
    currentAudioBuffer = audioBuffer;

    source.buffer = audioBuffer;

    analyser.fftSize = 512;

    try {
      gainNode.gain.setValueAtTime(this.volume, audioCtx.currentTime + 0.1);
    } catch (error) {
      gainNode.gain.value = this.volume;
    }

    if (source.buffer) {
      source.playbackRate.value = this.playbackRate;

      if (source.detune) {
        source.detune.value = this.detune;
      }

      source.connect(gainNode);
      source.loop = false;

      if (reportDuration) {
        document.dispatchEvent(new CustomEvent('player:duration-change', {
          detail: {
            duration: this.duration,
            orginalDuration: source.buffer.duration,
            playbackRate: this.computedPlaybackRate
          }
        }));
      }

      if (playToo) {
        this.play();
      }
    }
  }
}

export const nightcorePlayer = new NightcorePlayer();
export default NightcorePlayer;
