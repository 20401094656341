/**
 *
 * @param {AudioBuffer} orginalBuffer
 * @param {*} opt
 */
/*
 export async function audioBufferToMP3 (orginalBuffer, opt) {
  opt = opt || { detune: 0, playbackRate: 1.0 };

  const computedPlaybackRate = opt.playbackRate * Math.pow(2, opt.detune / 1200);
  const length = orginalBuffer.length / computedPlaybackRate;

  const offlineCtx = new OfflineAudioContext(orginalBuffer.numberOfChannels, length, orginalBuffer.sampleRate);
  const source = offlineCtx.createBufferSource();

  source.buffer = orginalBuffer;
  source.playbackRate.value = opt.playbackRate;

  if (source.detune) {
    source.detune.value = opt.detune;
  }

  const TOTAL_STACK = 5 * 1024 * 1024;
  const TOTAL_MEMORY = 16 * 1024 * 1024;
  const WASM_PAGE_SIZE = 64 * 1024;

  let dynamicTop = TOTAL_STACK;
  // TODO(Kagami): Grow memory?

  function sbrk (increment) {
    const oldDynamicTop = dynamicTop;

    dynamicTop += increment;

    return oldDynamicTop;
  }

  source.connect(offlineCtx.destination);
  source.start();

  const renderedAudioBuffer = await offlineCtx.startRendering();

  const importObject = {
    env: {
      memory: new WebAssembly.Memory({
        initial: renderedAudioBuffer.getChannelData(0).byteLength / WASM_PAGE_SIZE,
        maximum: renderedAudioBuffer.getChannelData(0).byteLength / WASM_PAGE_SIZE
      }),
      pow: Math.pow,
      exit: () => console.log('wasm exit'),
      powf: Math.pow,
      exp: Math.exp,
      sqrtf: Math.sqrt,
      cos: Math.cos,
      log: Math.log,
      sin: Math.sin,
      sbrk
    }
  };

  WebAssembly.instantiateStreaming(fetch('vmsg.wasm'), importObject).then(async results => {
    const { vmsg_init, vmsg_encode, vmsg_flush, vmsg_free } = results.instance.exports;

    const data = renderedAudioBuffer.getChannelData(0);

    let ref = null;

    ref = vmsg_init(renderedAudioBuffer.sampleRate);

    const pcm_l_ref = new Uint32Array(importObject.env.memory.buffer, ref, 1)[0];

    const pcm_l = new Float32Array(importObject.env.memory.buffer, pcm_l_ref);

    pcm_l.set(data);
    vmsg_encode(ref, data.length);

    const mp3_ref = new Uint32Array(importObject.env.memory.buffer, ref + 4, 1)[0];
    const size = new Uint32Array(importObject.env.memory.buffer, ref + 8, 1)[0];
    const mp3 = new Uint8Array(importObject.env.memory.buffer, mp3_ref, size);
    const blob = new Blob([mp3], { type: 'audio/mpeg' });

    vmsg_free(ref);

    console.log('blob:');
    console.log(blob);
    console.log(URL.createObjectURL(blob));
  });
}
*/

export async function audioBufferToWav (orginalBuffer, opt) {
  opt = opt || { detune: 0, playbackRate: 1.0 };

  const computedPlaybackRate = opt.playbackRate * Math.pow(2, opt.detune / 1200);
  const length = orginalBuffer.length / computedPlaybackRate;

  const offlineCtx = new OfflineAudioContext(orginalBuffer.numberOfChannels, length, orginalBuffer.sampleRate);
  const source = offlineCtx.createBufferSource();

  source.buffer = orginalBuffer;
  source.playbackRate.value = opt.playbackRate;

  if (source.detune) {
    source.detune.value = opt.detune;
  }

  source.connect(offlineCtx.destination);
  source.start();

  const buffer = await offlineCtx.startRendering();

  const numChannels = buffer.numberOfChannels;
  const sampleRate = buffer.sampleRate;
  const format = opt.float32 ? 3 : 1;
  const bitDepth = format === 3 ? 32 : 16;

  let result;

  if (numChannels === 2) {
    result = interleave(buffer.getChannelData(0), buffer.getChannelData(1));
  } else {
    result = buffer.getChannelData(0);
  }

  return URL.createObjectURL(new Blob([encodeWAV(result, format, sampleRate, numChannels, bitDepth)], { type: 'audio/wav' }));
}

function encodeWAV (samples, format, sampleRate, numChannels, bitDepth) {
  const bytesPerSample = bitDepth / 8;
  const blockAlign = numChannels * bytesPerSample;

  const buffer = new ArrayBuffer(44 + samples.length * bytesPerSample);
  const view = new DataView(buffer);

  /* RIFF identifier */
  writeString(view, 0, 'RIFF');
  /* RIFF chunk length */
  view.setUint32(4, 36 + samples.length * bytesPerSample, true);
  /* RIFF type */
  writeString(view, 8, 'WAVE');
  /* format chunk identifier */
  writeString(view, 12, 'fmt ');
  /* format chunk length */
  view.setUint32(16, 16, true);
  /* sample format (raw) */
  view.setUint16(20, format, true);
  /* channel count */
  view.setUint16(22, numChannels, true);
  /* sample rate */
  view.setUint32(24, sampleRate, true);
  /* byte rate (sample rate * block align) */
  view.setUint32(28, sampleRate * blockAlign, true);
  /* block align (channel count * bytes per sample) */
  view.setUint16(32, blockAlign, true);
  /* bits per sample */
  view.setUint16(34, bitDepth, true);
  /* data chunk identifier */
  writeString(view, 36, 'data');
  /* data chunk length */
  view.setUint32(40, samples.length * bytesPerSample, true);
  if (format === 1) { // Raw PCM
    floatTo16BitPCM(view, 44, samples);
  } else {
    writeFloat32(view, 44, samples);
  }

  return buffer;
}

function interleave (inputL, inputR) {
  const length = inputL.length + inputR.length;
  const result = new Float32Array(length);

  let index = 0;
  let inputIndex = 0;

  while (index < length) {
    result[index++] = inputL[inputIndex];
    result[index++] = inputR[inputIndex];
    inputIndex++;
  }

  return result;
}

function writeFloat32 (output, offset, input) {
  for (let i = 0; i < input.length; i++, offset += 4) {
    output.setFloat32(offset, input[i], true);
  }
}

function floatTo16BitPCM (output, offset, input) {
  for (let i = 0; i < input.length; i++, offset += 2) {
    const s = Math.max(-1, Math.min(1, input[i]));

    output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
  }
}

function writeString (view, offset, string) {
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
}
