/* eslint-disable no-unused-expressions */
import { registerFunctionComponent } from 'webact';
import { isPatron } from '../../helpers.js';

function DownloadButton () {
  const { html, css, postRender, $ } = this;

  postRender(() => {
    $(':host').addEventListener('click', async () => {
      const allowed = await isPatron();

      // @ts-ignore
      if (!('OfflineAudioContext' in window)) {
        document.dispatchEvent(new CustomEvent('status', {
          detail: {
            message: 'Your browser does not support downloading of songs. Try for example Google Chrome instead.'
          }
        }));
      }

      if (allowed) {
        document.dispatchEvent(new CustomEvent('player:trigger-download'));
      } else {
        document.dispatchEvent(new CustomEvent('status', {
          detail: {
            message: 'You are not allowed to download. You need to be an active subscriber on Patreon.'
          }
        }));
      }
    });
  });

  html`
    <button type="button">
      <svg width="15" height="15"><use href="img/icons.svg#download" /></svg>
    </button>
  `;

  css`
    button {
      all: unset;
    }

    svg {
      display: block;
      width: 1rem;
      height: 1rem;
      contain: strict;
    }
  `;
}

export default registerFunctionComponent(DownloadButton, {
  name: 'download-button'
});
