/* eslint-disable no-unused-expressions */
import { registerFunctionComponent } from 'webact';

const stylesheets = colorScheme => document.querySelectorAll(`link[rel=stylesheet][media*=prefers-color-scheme][media*="${colorScheme}"]`);
const preference = colorScheme => window.matchMedia(`(prefers-color-scheme: ${colorScheme})`);

function DarkModeToggle () {
  const { html, css, postRender, $ } = this;

  html`
    <app-button>
      <slot name="dark"></slot>
      <slot name="light"></slot>
      <slot name="text"></slot>
    </app-button>
  `;

  css`
  :host {
    display: flex;
    gap: 1em;
  }

  figure {
    display: contents;
  }

  ::slotted(svg) {
    display: none;
  }

  :host-context([color-scheme="dark"]) ::slotted([slot="dark"]) {
    display: block;
  }

  :host-context([color-scheme="light"]) ::slotted([slot="light"]) {
    display: block;
  }
  `;

  const darkCSS = stylesheets('dark');
  const darkPreference = preference('dark');
  let mode = darkPreference.matches ? 'dark' : 'light';

  $().setAttribute('color-scheme', mode);

  const changeColorScheme = colorScheme => {
    const event = new CustomEvent('colorschemechange', {
      bubbles: true,
      composed: true,
      detail: {
        colorScheme
      }
    });

    document.dispatchEvent(event);

    $().setAttribute('color-scheme', colorScheme);

    mode = colorScheme;

    darkCSS.forEach(link => {
      link.media = colorScheme === 'dark' ? 'all' : 'not all';
      link.disabled = colorScheme !== 'dark';
    });
  };

  postRender(() => {
    darkPreference.addEventListener('change', e => changeColorScheme(e.matches ? 'dark' : 'light'));
    $().addEventListener('click', () => changeColorScheme(mode === 'dark' ? 'light' : 'dark'));
  });
}

export default registerFunctionComponent(DarkModeToggle, {
  name: 'dark-mode-toggle'
});
