import { Component, registerComponent } from 'webact';
import { nightcorePlayer } from '../../nightcore-player.js';

export class CoverArt extends Component {
  constructor () {
    super('/js/components/cover-art/cover-art.js');
  }

  componentDidMount () {
    const image = this.$('img');

    nightcorePlayer.currentImageObserver.observe(imageUrl => {
      image.src = imageUrl;
    });
  }
}

export default registerComponent(CoverArt, {
  name: 'cover-art'
});
