class Observer {
  constructor () {
    this.observers = [];
  }

  observe (callback) {
    this.observers.push(callback);
  }

  notifyListeners (data) {
    this.observers.forEach(callback => callback(data));
  }
}

export default Observer;
